import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  
  body {  
    font-family: -apple-system, Roboto, sans-serif, serif;
  }
  
  a {
    text-decoration: none;
  }
`;
 
export default GlobalStyle;